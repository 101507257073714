const EARTH_RADIUS = 6371;

function getTotals(data, listCountries) {
  let totalPopulation = 0 ;
  let totalGdp = 0 ;
  for (let i=0 ; i<listCountries.length; i++) {
    const gdp = data['gdp'][i] ;
    const population = data['pop'][i] ;
    if (gdp>0 && population>0) {
      totalPopulation += population ;
      totalGdp += gdp ;
    }
  }
  totalPopulation = totalPopulation/1000000 ;
  totalGdp = totalGdp/1000000000 ;
  return {pop: totalPopulation, gdp: totalGdp} ;
}

function geo2angles(lon, lat) {
    const lambda = (lon * Math.PI) / 180; 
    const phi = (lat * Math.PI) / 180;
    return [
      Math.cos(phi) * Math.cos(lambda), 
      Math.cos(phi) * Math.sin(lambda), 
      Math.sin(phi)                    
    ];
}

function toCartesianCoordinates(longitude, latitude) {
  const latRad = latitude * (Math.PI / 180);
  const lonRad = longitude * (Math.PI / 180);
  const X = EARTH_RADIUS * Math.cos(latRad) * Math.cos(lonRad);
  const Y = EARTH_RADIUS * Math.cos(latRad) * Math.sin(lonRad);
  const Z = EARTH_RADIUS * Math.sin(latRad);
  return { X, Y, Z };
}

function toGeographicCoordinates(X, Y, Z) {
  const r = Math.sqrt(X * X + Y * Y + Z * Z);
  const longitude = Math.atan2(Y, X) * (180 / Math.PI);
  const latitude = Math.atan2(Z, Math.sqrt(X * X + Y * Y)) * (180 / Math.PI);
  return { lon:longitude, lat:latitude, r: r };
}

function getPosition(from, to, r) {
  const fraction = r / EARTH_RADIUS ;
  const x = from[0] + fraction * (to[0] - from[0]) ;
  const y = from[1] + fraction * (to[1] - from[1]) ;
  return [x, y] ;
}

function getBarycenterCartesianCoords(data, listCountries, countryIndex, countryCoords) {
    let avgX = 0 ;
    let avgY = 0 ;
    let avgZ = 0 ;
    let totalGdp = 0 ;
    for (const country of listCountries) {
      const i = countryIndex[country] ;
      const lon = countryCoords[country][0] ;
      const lat = countryCoords[country][1] ;
      const {X, Y, Z} = toCartesianCoordinates(lon, lat) ;
      const gdp = data['gdp'][i] ;
      if (gdp>0) {
        totalGdp += gdp ;
        avgX += X*gdp ;
        avgY += Y*gdp ;
        avgZ += Z*gdp ;
      }
    }
    if (totalGdp>0) {
      avgX /= totalGdp ;
      avgY /= totalGdp ;
      avgZ /= totalGdp ;
    }
    return {X: avgX, Y: avgY, Z: avgZ} ;
}

function getBarycenterGeographicCoords(data, listCountries, countryIndex, countryCoords) {
    const cart = getBarycenterCartesianCoords(data, listCountries, countryIndex, countryCoords) ;
    return toGeographicCoordinates(cart.X, cart.Y, cart.Z) ;
}

function getDistanceFromSurface(point) {
  const r = Math.sqrt(point.X * point.X + point.Y * point.Y + point.Z * point.Z);
  return EARTH_RADIUS - r ;
}

function getMetrics(yearIndex, years, gdpData, listCountries, countryIndex, countryCoords, maxPopulation, maxGdp) {
    const year = years[yearIndex] ;
    const data = gdpData.stats[''+year] ;
    const totals = getTotals(data, listCountries) ;
    const totalPopulation = Math.round(totals.pop, 2) ;
    const totalGdp = Math.round(totals.gdp, 2) ;
    const popPercent = Math.round(100 * totalPopulation / maxPopulation) ;
    const gdpPercent = Math.round(100 * totalGdp / maxGdp) ;
    const barycenterCoords = getBarycenterCartesianCoords(data, listCountries, countryIndex, countryCoords) ;
    const barycenterRay = Math.round(getDistanceFromSurface(barycenterCoords)) ;
    const barycenterRayPercent = Math.round(100 * barycenterRay / EARTH_RADIUS) ;
    const refDataIndex = parseInt(yearIndex)>0 ? parseInt(yearIndex)-1 : parseInt(yearIndex)+1 ;
    const refData = gdpData.stats[''+years[refDataIndex]] ;
    const refBarycenterCoords = getBarycenterCartesianCoords(refData, listCountries, countryIndex, countryCoords) ;
    const numYears = Math.abs(years[yearIndex] - years[refDataIndex]) ;
    const distance = Math.sqrt((barycenterCoords.X - refBarycenterCoords.X)**2 + 
                               (barycenterCoords.Y - refBarycenterCoords.Y)**2 + 
                               (barycenterCoords.Z - refBarycenterCoords.Z)**2) ;
    const speed = Math.round(distance / numYears) ;
    return {
      barycenterCoords,
      totalPopulation,
      totalGdp,
      popPercent,
      gdpPercent,
      barycenterRay,
      barycenterRayPercent,
      speed
    }
}


export {
EARTH_RADIUS,
getTotals,
geo2angles,
toCartesianCoordinates,
toGeographicCoordinates,
getPosition,
getBarycenterCartesianCoords,
getBarycenterGeographicCoords,
getDistanceFromSurface,
getMetrics
} ;