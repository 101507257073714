import { geoOrthographic, geoPath, geoGraticule } from 'd3';
import React, { Component } from 'react';
import $ from 'jquery';
import {geo2angles, getPosition, getBarycenterGeographicCoords} from './utils.js' ;


const width = 500;
const height = 500;


class EarthView extends Component {

  constructor(props) {
    super(props);    
    this.state = {
      mouseDown: false,
      lastMousePosition: null,
      rotation: [0, 0, 0]  // Track current rotation
    };
    this.projection = geoOrthographic().scale(250).translate([width / 2, height / 2]);
    this.path = geoPath(this.projection);
    this.graticule = geoGraticule();
    // Bind methods to the instance
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  handleMouseDown(event) {
    this.setState({
      mouseDown: true,
      lastMousePosition: { x: event.clientX, y: event.clientY }
    });
  }

  handleMouseUp() {
    $('.marks').css('cursor', '');
    this.setState({
      mouseDown: false,
      lastMousePosition: null
    });
  }

  handleMouseMove(event) {
    const { mouseDown, lastMousePosition, rotation } = this.state;

    if (!mouseDown) return;

    const { clientX, clientY } = event;
    if (lastMousePosition) {
      const deltaX = clientX - lastMousePosition.x;
      const deltaY = clientY - lastMousePosition.y;

      // Update the rotation based on mouse movement (invert Y axis for better control)
      const newRotation = [
        rotation[0] + deltaX / 2,  // Adjust rotation sensitivity by dividing delta
        rotation[1] - deltaY / 2,  // Invert Y for a natural globe feel
        rotation[2],
      ];

      // Apply the updated rotation to the projection
      this.projection.rotate(newRotation);

      // Update the last mouse position and current rotation state
      this.setState({
        lastMousePosition: { x: clientX, y: clientY },
        rotation: newRotation
      });

      // Visual feedback: change the cursor and background
      $('.marks').css('cursor', 'pointer');

    }
  }

  getBarycenter(year) {
    const listCountries = this.props.listCountries ;
    const countryIndex = this.props.countryIndex ;
    const countryCoords = this.props.countryCoords ;
    const data = this.props.stats[''+year] ;
    const {lon, lat, r} = getBarycenterGeographicCoords(data, listCountries, countryIndex, countryCoords) ;
    const isVisible = this.getVisible(lon, lat) ;
    const proj = this.projection([lon, lat]) ;
    const zero = [width/2, height/2] ;
    const exact = getPosition(zero, proj, r) ;
    return {
      year: year,
      exact: exact,
      proj: proj,
      r: r,
      isVisible: isVisible
    }
  }

  getVisible(lon, lat) {
    // 1. Convert the point to 3D Cartesian coordinates
    const point3D = geo2angles(lon, lat);
    // 2. Convert the current projection's rotation to a Cartesian vector
    const [lambda, phi] = this.state.rotation.map((angle) => (angle * Math.PI) / 180);
    // The visible center is simply the inverse of the rotation angles
    const visibleCenter = geo2angles(-lambda * (180 / Math.PI), -phi * (180 / Math.PI));
    // 3. Compute the dot product of the point and the visible center
    const dotProduct = point3D[0] * visibleCenter[0] + point3D[1] * visibleCenter[1] + point3D[2] * visibleCenter[2];
    // 4. If the dot product is positive, the point is visible
    return dotProduct>=0 ;
  }

  renderBarycenterInside(key, bary, highlight) {
    if (bary.isVisible) {
      const color1 = highlight ? 'red' : 'gray' ;
      return (
        <g key={key}>
          <line 
            x1={width/2} 
            y1={height/2} 
            x2={bary.proj[0]} 
            y2={bary.proj[1]} 
            stroke="blue"
            strokeWidth="1"
          />
          <circle cx={bary.exact[0]} cy={bary.exact[1]} r={4} fill={color1} />
        </g>
      )
    } else {
      return null ;
    }
  }

  renderBarycenterOutside(key, bary, highlight) {
    if (bary.isVisible) {
      const color2 = highlight ? 'yellow' : 'white' ;
      return (
        <g key={key}>
          <circle cx={bary.proj[0]} cy={bary.proj[1]} r={8} fill={color2} />  
          <text 
            x={bary.proj[0]-8}
            y={bary.proj[1]} 
            fontSize="6" 
            fill="black"
            alignmentBaseline="middle"
          >{bary.year}</text>
        </g>
      )
    } else {
      return null ;
    }
  }

  renderPathInside(path) {
    const ans = [] ;
    for (let i=0; i<path.length; i++) {
      ans.push(this.renderBarycenterInside('pathbaryin'+i, path[i], false))
    }
    return ans ;
  }

  renderPathOutside(path) {
    const ans = [] ;
    for (let i=0; i<(path.length-1); i++) {
      const bary_from = path[i] ;
      const bary_to = path[i+1] ;
      const key = 'path'+i
      if (bary_from.isVisible && bary_to.isVisible) {
        ans.push(<line 
          key={key}
          x1={bary_from.proj[0]} 
          y1={bary_from.proj[1]} 
          x2={bary_to.proj[0]} 
          y2={bary_to.proj[1]} 
          stroke="gray" 
          strokeWidth="1"
        />) ; 
      }
    }
    for (let i=0; i<path.length; i++) {
      ans.push(this.renderBarycenterOutside('pathbaryout'+i, path[i], false))
    }
    return ans ;
  }

  render() {
    const lands = this.props.lands ;
    const interiors = this.props.interiors ;
    const currentBary = this.getBarycenter(this.props.year) ;
    const path_years = [1000, 1820, 1850, 1880, 1910, 1945, 2000, 2010, 2022]
    const path_bary = path_years.map(year => this.getBarycenter(year)) ;
    return (
      <g className="marks" 
         onMouseDown={this.handleMouseDown} 
         onMouseMove={this.handleMouseMove} 
         onMouseUp={this.handleMouseUp}
      >
        {this.renderPathInside(path_bary)}
        {this.renderBarycenterInside('in', currentBary, true)}
        <path className="sphere" d={this.path({ type: 'Sphere' })} />
        <path className="graticule" d={this.path(this.graticule())} style={{ display: 'none' }} />
        {lands.features.map((feature, index) => (
          <path key={index} className="feature" d={this.path(feature)} />
        ))}
        <path className="interiors" d={this.path(interiors)} />
        {this.renderPathOutside(path_bary)}
        {this.renderBarycenterOutside('out', currentBary, true)}
      </g>
    );
  }
}

export default EarthView;
