import React from 'react';
import logo from './dsart_logo.png';
import { Container, Row, Col, Navbar, Form, ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { feature, mesh } from 'topojson-client';
import { geoCentroid } from 'd3-geo';
import topojsonData from './topojsonData.json' ;
import gdpData from './gdpData.json' ;
import EarthView from './EarthView';
import { getTotals, getMetrics } from './utils';


const {countries,land} = topojsonData.objects
const lands = feature(topojsonData, land) ;
const interiors = mesh(topojsonData, countries, (a, b)=>  a !== b) ;
const listCountries = countries.geometries.map(c => c.properties.name) ;
const years = Object.keys(gdpData.stats) ;
const countryIndex = gdpData.countryIndex ;

// Country coords map
const countryCoords = {} ;
for (const c of countries.geometries) {
  const countryGeoJSON = feature(topojsonData, c); 
  const centroidCoords = geoCentroid(countryGeoJSON);
  const name = c.properties.name ;
  countryCoords[name] = centroidCoords ;
}

// Populations and GDPs
const pops = [] ;
const gdps = [] ;
for (const year of years) {
  const data = gdpData.stats[''+year] ;
  const totals = getTotals(data, listCountries) ;
  pops.push(totals.pop) ;
  gdps.push(totals.gdp) ;
}
const maxPopulation = Math.max(...pops) ;
const maxGdp = Math.max(...gdps) ;

// Metrics
const metrics = {} ;
for (let i=0; i<years.length; i++) {
  const m = getMetrics(i, years, gdpData, listCountries, countryIndex, countryCoords, maxPopulation, maxGdp) ;
  metrics[years[i]] = m ;
}
const maxSpeed = Math.max(...Object.values(metrics).map(m => m.speed)) ;
for (const m of Object.values(metrics)) {
  m.speedPrct = Math.round(100 * m.speed / maxSpeed) ;
}

class App extends React.Component {

  state = {
    yearIndex: 0
  }

  setYearIndex(y) {
    this.setState({yearIndex: y}) ;
  }

  render() {
    const yearIndex = this.state.yearIndex ;
    const year = years[yearIndex] ;
    const {
      barycenterCoords,
      totalPopulation,
      totalGdp,
      popPercent,
      gdpPercent,
      barycenterRay,
      barycenterRayPercent,
      speed,
      speedPrct
    } = metrics[year] ;
    return (
      <Container fluid>

        <Row>

          <Col xs="12" lg="4">
            <Row>
              <Col style={{marginTop:'20px'}}> 
                <h4>World GDP Center</h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>YEAR <b>{years[yearIndex]}</b></Form.Label>
              </Col>
              <Col>
                <Form.Range
                  style={{width:'250px'}}
                  min={0}
                  max={years.length - 1}
                  step={1}
                  value={yearIndex}
                  onChange={(e) => this.setYearIndex(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Population <b>{totalPopulation.toLocaleString('en-US')} M</b></Form.Label>
              </Col>
              <Col>
                <ProgressBar now={popPercent} label={popPercent+'%'} />
              </Col>
            </Row> 
            <Row>
              <Col>
                <Form.Label>GDP <b>{totalGdp.toLocaleString('en-US')} B</b></Form.Label>
              </Col>
              <Col>
                <ProgressBar now={gdpPercent} label={gdpPercent+'%'} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Depth&nbsp;
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="coords-tooltip">Distance between the barycenter and its projection on the surface of the sphere.</Tooltip>}>
                    <span className="text-info">ⓘ</span>
                  </OverlayTrigger>
                  &nbsp;&nbsp;<b>{barycenterRay} Km</b></Form.Label>
              </Col>
              <Col>
                <ProgressBar now={barycenterRayPercent} label={barycenterRayPercent+'%'} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Speed&nbsp;
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="coords-tooltip">Since last data point.</Tooltip>}>
                    <span className="text-info">ⓘ</span>
                  </OverlayTrigger>
                  &nbsp;&nbsp;<b>{speed} Km/year</b></Form.Label>
              </Col>
              <Col>
                <ProgressBar now={speedPrct} label={speedPrct+'%'} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>
                  Coords&nbsp;
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="coords-tooltip">Cartesian coordinates of the barycenter in Km. X=PacificToAfrica Y=AmericaToAsia Z=SouthPoleToNorthPole</Tooltip>}
                  >
                    <span className="text-info">ⓘ</span>
                  </OverlayTrigger>
                </Form.Label>
              </Col>
              <Col>
                X={Math.round(barycenterCoords.X)}
              </Col>
              <Col>
                Y={Math.round(barycenterCoords.Y)}
              </Col>
              <Col>
                Z={Math.round(barycenterCoords.Z)}
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col>
                <h5>Side Note</h5>
                <p>For history, lines exist of the movement of human wills, one end of which is hidden in the unknown but at the other end of which a
                   consciousness of man’s will in the present moves in space, time, and
                   dependence on cause.</p>
                <p>The more this field of motion spreads out before our eyes, the more evident are the laws of that movement.</p>
                <p>To discover and define those laws is the problem of history.</p>
                <strong>-- Leo Tolstoy, <i>War and Peace</i></strong>
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col>
                <h5>Credits</h5>
                <p>I wanted to replicate this <a href="https://www.businessinsider.com/mckinsey-worlds-economic-center-of-gravity-2012-6">map</a> with latest data, and understand it a bit deeper by looking at it in 3D.</p>
                <p>Data from <a href="https://www.rug.nl/ggdc/historicaldevelopment/maddison/releases/maddison-project-database-2023">Maddison Project Database 2023</a></p>
                <p>Useful code snippets from <a href="https://github.com/netguintech/Globe">netguintech</a></p>
              </Col>
            </Row>
          </Col>

          <Col xs="12" lg="8" className="world-background">
            
            <svg width="750" height="600" viewBox="-50 -50 750 600" className="d-block m-auto">
              <EarthView lands={lands} 
                         interiors={interiors} 
                         listCountries={listCountries} 
                         countryIndex={countryIndex} 
                         countryCoords={countryCoords}
                         year={year}
                         stats={gdpData.stats} />
            </svg>

          </Col>

        </Row>

        <Navbar style={{ backgroundColor: '#ededed', fontSize: 'large', fontWeight: '800' }} fixed="bottom">
          <Container style={{ justifyContent: 'center' }} >
            <Row>
              <Col lg="12" className="text-center">
                  <a href="https:/www.datascience.art">
                    <img
                      alt=""
                      src={logo}
                      width="30"
                      height="30"
                    />
                  </a>
                  &nbsp;&nbsp;
                  <a href="https:/www.datascience.art">
                     Data Science Art
                  </a>
              </Col>
            </Row>
          </Container>
        </Navbar>

      </Container>
    );
  }
}

export default App;


